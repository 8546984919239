import { formatEmptyState, isEmpty } from "common";

export const formatNumber = (num: number, short: boolean = false) => {
  if (!short) return num.toLocaleString();
  if (num >= 1000000) {
    const divided = num / 1000000;
    return divided % 1 === 0 ? divided.toFixed(0) + "M" : divided.toFixed(1) + "M";
  } else if (num >= 1000) {
    const divided = num / 1000;
    return divided % 1 === 0 ? divided.toFixed(0) + "K" : divided.toFixed(1) + "K";
  }
  return num.toString();
};

export function formatDiff(diff: number, space?: boolean): string {
  if (diff > 0) {
    if (space) {
      return `+ ${Math.round(diff).toLocaleString()}`;
    } else {
      return `+${Math.round(diff).toLocaleString()}`;
    }
  } else {
    return `${Math.round(diff).toLocaleString()}`;
  }
}

export function calculatePercentage(num: number, total: number): number {
  if (num > 0 && total > 0) {
    return Math.round((Math.abs(num) / total) * 100);
  }
  return 0;
}

export interface Diff {
  value: number;
  pct: number;
  change: DiffVariant;
}
type DiffVariant = "loss" | "gain" | "none";
export function calculatePercentageDiff(num: number, total: number): Diff {
  if (total > 0) {
    const change: DiffVariant = num < 0 ? "loss" : "gain";
    return { value: num, pct: (Math.abs(num) / total) * 100, change };
  }
  return { value: 0, pct: 0, change: "none" };
}
export function formatPercentageDiff(current: number, pct: number): Diff {
  if (pct > 0) {
    const change: DiffVariant = current < 0 ? "loss" : "gain";
    return { value: current, pct: Math.abs(pct), change };
  }
  return { value: 0, pct: 0, change: "none" };
}

export function formatRankSuffix(rank: number): string {
  const lastDigit = rank % 10;
  const lastTwoDigits = rank % 100;

  if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
    return `th`;
  }

  switch (lastDigit) {
    case 1:
      return `st`;
    case 2:
      return `nd`;
    case 3:
      return `rd`;
    default:
      return `th`;
  }
}

export function formatPercentage(value: number) {
  if (Math.abs(value) >= 1000) {
    return `${(value / 1000).toFixed(1)}K%`;
  } else {
    return `${Math.round(value)}%`;
  }
}

export function removeLeadingZeroDecimal(value: number | string): string {
  // if (Number(value) === 0) {
  //   return `${value}`;
  // }
  if (isEmpty(value)) {
    return formatEmptyState("n/a");
  }
  return value.toString().replace(/^0+/, "");
}

export function formatUnits(value: number): string {
  return `${value > 0 ? "+" : ""}${value ? value.toLocaleString() : 0}`;
}
